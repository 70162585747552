@import "../../global/theme.css";
:root {
    --accordionSummaryExpand: {
        background-color: var(--blue300);
        color: var(--white);
        fill: var(--white);
        min-height: auto;
    };
}

.container {
    border-radius: 5px;

    .accordionRoot:before {
        background-color: transparent;
    }

    .accordionRoot {
        color: var(--blue300);
        fill: var(--blue300);
        font-size: 14px;
        font-weight: 600;
        border-left: solid 1px var(--blue300);
        border-right: solid 1px var(--blue300);
        border-top: solid 1px var(--blue300);
        box-shadow: none;

        .accordionIcon {
            margin-bottom: 5px;
        }

        .accordionDetails {
            color: var(--gray700);
            font-weight: 400;
            padding: 13px 44px 13px 13px;
        }

        .accordionSummaryExpandContent {
            margin: 15px 0;
        }

        .accordionSummaryExpand {
            @apply --accordionSummaryExpand;
        }

        .accordionSummaryExpandFirst {
            border-radius: 3px 3px 0 0;
            @apply --accordionSummaryExpand;
        }
    }

    .accordionRoot:first-child {
        border-radius: 5px 5px 0 0;
    }

    .accordionRoot:last-child {
        border-bottom: solid 1px var(--blue300);
    }

    .accordionRootExpanded {
        margin: 0 !important;
    }

    .accordionRootExpanded:first-child {
        border-radius: 4px 4px 0 0;
    }
}