@import '../../../../global/theme.css';

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    width: 100%;

    & .backButton {
        border: 1px solid var(--blue300);
        & .backText {
            color: var(--blue300);
        }
        &:hover {
            border: none !important;
            background-color: var(--primaryButtonBackground) !important;

            & .backText {
                color: var(--white);
            }

            & .arrow.back {
                fill: var(--white);
            }
        }
    }

    & .skip {
        color: var(--blue300);
        cursor: pointer;
        font-weight: 600;
        font-size: 13px;
        line-height: 19px;
        text-decoration: underline;
    }

    & .arrow {
        width: 11px;
        height: 8px;

        &.next {
            fill: var(--white);
            margin-left: 10px;
            transform: rotate(-90deg);
        }

        &.back {
            fill: var(--blue300);
            margin-right: 10px;
            transform: rotate(90deg);
        }
    }
}
