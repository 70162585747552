@import "../../global/theme.css";

.alertContainer {
    display: flex;
    margin: 0 0 16px;
    color: var(--gray800);
    border-radius: 8px;
    padding: 7px;

    .alertIcon {
        margin:  3px 8px 3px 0;
    }

    .alertText {
        font-size: 14px;
        font-weight: 600;
        color: var(--gray700);
        line-height: 1.6;
    }
}

.alertWarning {
    background-color: var(--orange100);
}