@import "../../global/theme.css";

.suggestionRow {
    font-size: 13px;
    padding-left: 8px;
    height: 30px;
    cursor: pointer;
    border-bottom: 1px solid;
    border-color: var(--gray200);
    color: var(--gray700);
    font-weight: 400;
    display: flex;
    align-items: center;

    &:hover {
        background-color: var(--gray200) !important;

        &:not(.disabled) {
            color: var(--gray700) !important;
            background-color: var(--hoverColor) !important;
        }
    }

    &.disabled {
        color: var(--gray500);
        cursor: default;
        padding: 4px 10px;
    }

    & .subLabel {
        color: var(--gray500);
        font-size: 11px;
        margin-left: 5px;
        margin-top: 1px;
        font-style: italic;

    }

    & .extra {
        color: var(--gray500);
        font-size: 9px;
        margin-left: auto;
        margin-right: 1px;
        margin-top: 1px;
        font-style: italic;
        white-space: nowrap;
    }

    & .extraOnHover {
        opacity: 0;
    }

    &:hover {
        & .extraOnHover {
            color: var(--gray600);
            opacity: 1;
            font-size: 12px;
            margin-left: auto;
            margin-top: 1px;
        }
    }

    & .icon {
        padding-right: 5px;
        display: flex;
    }

    & .subTitle {
        color: var(--gray400);
        padding-left: 6px;
        padding-right: 14px;
    }

    & .editIcons {
        margin: auto 0 auto auto;
        display: flex;
        flex-direction: row;

        & .editIcon {
            padding-right: 6px;
            fill: var(--gray400);

            &:hover {
                fill: var(--blue300);
            }
        }

        & .trashIcon {
            padding-right: 6px;
            color: var(--gray400);

            &:hover {
                color: var(--red300);
            }
        }
    }
}

.highlighted {
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    color: inherit;
}