.container {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .checkboxContainer {
        width: 100%;
        margin: 3px 0;
    }

    .checkmarkLabel {
        font-weight: 500;
    }

    .lastInputContainer {
        padding-bottom: 12px;
    }

    .icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        margin-right: 8px;
    }
}