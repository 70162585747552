@import "../Main.css";

.container {
    & .title {
        font-size: 18px;
        color: var(--gray800);
        margin-bottom: 20px;
      }
    & .text {
        font-size: 14px;
        color: var(--gray700);
        margin-bottom: 30px;
      }
    & .actionButtons {
        display: flex;
        justify-content: flex-end;
        & .acceptButton {
            margin-left: 5px;
          }
      }
    & .floatRight {
        float: right;
      }
    & .videoFrame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
    }
}
.video {
    width: 60% !important;
    height: 34vw !important;
    position: relative !important;
    background-color: black !important;
}
