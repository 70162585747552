@import "../../global/theme.css";

.bulletItem {
    align-items: unset !important;
    flex-direction: row;
    padding-top: 0 !important;

    & .bullet {
        background-color: var(--blue300);
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-top: 7px;
        display: inline-block;
    }

    & .bulletText {
        margin-left: 10px;
        width: 100%;
    }
}


