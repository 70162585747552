.container {
    margin-top: 20px;

    & .closedHeaderContainer {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;

        & .icon {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            margin-right: 8px;
        }

        & .closedHeaderSuffix {
            font-weight: 400;
        }
    }

    & .formContainer {
        gap: 8px;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;

        & .dropdownContainer {
            width: 100%;
        }
    }
}

.addNetworkButton {
    margin-top: 20px;
    cursor: pointer;
    color: var(--blue300);
    font-weight: 600;

    &.disabled {
        color: var(--gray500);
        cursor: not-allowed !important;
    }
}
